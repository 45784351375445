<template>
  <div class="user-box">
    <div class="user-box-top">
      <div class="user-box-top-charts" ref="pieBox">
        <Chart style="height: 100%" :option="pieOptions" />
        <div class="user-box-top-charts-statistics" :style="{height: pieCW + 'px', width: pieCW + 'px'}"> 
          <div class="user-box-top-charts-statistics-num">{{ options.userTypeTotal || '--' }}</div>
          <div class="user-box-top-charts-statistics-ornament"></div>
          <div class="user-box-top-charts-statistics-name">用户总数</div>
        </div>
      </div>
      <div class="user-box-top-ornament"></div>
      <div class="user-box-top-info">
        <div class="user-box-top-info-item" :class="{ 'user-box-top-info-item2': item.typeName == '常规客户', 'user-box-top-info-item3': item.typeName == '风险客户' }" v-for="item,index in options.userTypeStatistics" :key="index">
          <div class="user-box-top-info-item-square" :class="{ 'user-box-top-info-item2-square': item.typeName == '常规客户', 'user-box-top-info-item3-square': item.typeName == '风险客户' }"></div>
          <div class="user-box-top-info-item-long" :class="{ 'user-box-top-info-item2-long': item.typeName == '常规客户', 'user-box-top-info-item3-long': item.typeName == '风险客户' }"></div>
          <div class="user-box-top-info-item-text">{{ item.typeName || '--' }}</div>
          <div class="user-box-top-info-item-num" :class="{ 'user-box-top-info-item2-num': item.typeName == '风险客户', 'user-box-top-info-item3-num': item.typeName == '风险客户' }"><span><span style="font-weight: bold;">{{ item.typeThan || '--' }}</span>%</span> {{ item.typeNumber || '--' }}</div>
        </div>
        <!-- <div class="user-box-top-info-item user-box-top-info-item2">
          <div class="user-box-top-info-item-square user-box-top-info-item2-square"></div>
          <div class="user-box-top-info-item-long user-box-top-info-item2-long"></div>
          <div class="user-box-top-info-item-text">优质用户</div>
          <div class="user-box-top-info-item-num user-box-top-info-item2-num"><span>50%</span> 5555</div>
        </div>
        <div class="user-box-top-info-item user-box-top-info-item3">
          <div class="user-box-top-info-item-square user-box-top-info-item3-square"></div>
          <div class="user-box-top-info-item-long user-box-top-info-item3-long"></div>
          <div class="user-box-top-info-item-text">优质用户</div>
          <div class="user-box-top-info-item-num user-box-top-info-item3-num"><span>50%</span> 5555</div>
        </div> -->
      </div>
    </div>
    <div class="user-box-type">
      <div class="user-box-type-item" :class="{'user-box-type-current': userType === index + 1}" @click="handleChangeUserType(index + 1)" v-for="item,index in options.userTypeStatistics" :key="index">
        <img class="user-box-type-item-icon" v-if="item.typeName == '优质客户'" src="../../../../assets/images/bigScreen/customerInfo/excellent.png" alt="" srcset="">
        <img class="user-box-type-item-icon" v-else-if="item.typeName == '常规客户'" src="../../../../assets/images/bigScreen/customerInfo/routine.png" alt="" srcset="">
        <img class="user-box-type-item-icon" v-else src="../../../../assets/images/bigScreen/customerInfo/risk.png" alt="" srcset="">
        <div class="user-box-type-item-text">
          <span>{{ item.typeName || '--' }}</span>
          <span>{{ item.typeNumber || '--' }}位</span>
          <span>{{ item.typeThan || '--' }}%</span>
        </div>
      </div>
      <!-- <div class="user-box-type-item" :class="{'user-box-type-current': userType == 2}" style="margin-top: -2%;">
        <img class="user-box-type-item-icon" @click="handleChangeUserType(2)" src="../../../../assets/images/bigScreen/customerInfo/routine.png" alt="" srcset="">
        <div class="user-box-type-item-text">
          <span>常规客户</span>
          <span>2323位</span>
          <span>30%</span>
        </div>
      </div>
      <div class="user-box-type-item" :class="{'user-box-type-current': userType == 3}" style="margin-top: -2%;">
        <img class="user-box-type-item-icon" @click="handleChangeUserType(3)" src="../../../../assets/images/bigScreen/customerInfo/risk.png" alt="" srcset="">
        <div class="user-box-type-item-text">
          <span>风险客户</span>
          <span>2323位</span>
          <span>30%</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import * as echarts from "echarts";
export default {
  components: { Chart },
  data() {
    return {
      pieOptions: {},
      pieCW: 0,
      userType: ''
    };
  },
  props: {
    options: {
      type: Object,
      default: ()=>{}
    }
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(() => {
          this.initCharts(v.userTypeStatistics || []);
        });
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // getOilAndTimeTrend().then(res=>{
      //    // this.quantityNum = interval(res.obj.totalUser || 0);
      this.$nextTick(() => {
        this.initCharts();
      });
      // })
    },
    getSize(res, type) {
      const clientWidth = this.$refs["pieBox"].offsetWidth;
      const clientHeight = this.$refs["pieBox"].offsetHeight;
      if (!clientWidth) return;
      console.log(clientWidth, clientHeight);
      this.pieCW = (clientWidth > clientHeight ? clientHeight : clientWidth) * 0.5;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initCharts(data = []) {
      var scale = this.getSize(0.3, 2);
      let dataList = data.map(val=>{
        return {
                value: val.typeThan,
                naem: val.typeName,
                itemStyle: {
                  color: "rgba(9, 42, 73, 1)",
                },
        }
      })
      this.pieOptions = {
        label: {
          show: false,
        },
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: "情况统计",
            type: "pie",
            selectedMode: "single",
            radius: ["60%", "70%"],
            label: {
              show: false,
            },
            padAngle: 4,
            // itemStyle: {
            //   borderColor: "rgba(255,255,255,1)",
            //   borderWidth: 8
            // },
            data: dataList,
          },
          {
            name: "情况统计",
            type: "pie",
            radius: ["70%", "80%"],
            label: {
              show: false,
            },
            padAngle: 4,
            // itemStyle: {
            //   borderColor: "transparent",
            //   borderWidth: 8 
            // },
            data: data.map(val=>{ 
              return {
                value: val.typeThan,
                name: val.typeName,
                itemStyle: {
                  color: val.typeName == '优质客户'  ? new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
	                        offset: 0,
	                        color: 'rgba(9, 224, 95, 1)'
	                    }, {
	                        offset: 1,
	                        color: 'rgba(127, 233, 162, 1)'
	                    }]) : val.typeName == '常规客户'  ? new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
	                        offset: 0,
	                        color: 'rgba(27, 130, 245, 1)'
	                    }, {
	                        offset: 1,
	                        color: 'rgba(57, 215, 255, 1)'
	                    }]) : new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
	                        offset: 0,
	                        color: 'rgba(241, 97, 69, 1)'
	                    }, {
	                        offset: 1,
	                        color: 'rgba(255, 150, 47, 1)'
	                    }]),
                },
              }
             })
          },
        ],
      };
    },
    handleChangeUserType(e) {
      this.userType == e ? this.userType = "" : this.userType = e;
      this.$nextTick(()=>{
        this.$emit('changeUserType',this.userType);
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.user-box {
  height: 93%;
  padding: 1% 2%;
  &-top {
    display: flex;
    height: 50%;
    padding-left: 15%;
    &-charts {
      position: relative;
      height: 100%;
      flex: 1;
      &-statistics {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // border-radius: 50%;
        background-image: url('./../../../../assets/images/bigScreen/customerInfo/chartsCircle.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        &-ornament {
          width: 80%;
          height: 1px;
          margin: 0.08rem 0;
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #FFFFFF 50%, rgba(255,255,255,0) 100%);
          opacity: 0.5;
        }
        &-name {
          font-size: 0.13rem;
          color: #FFFFFF;
        }
        &-num {
          font-size: 0.2rem;
          color: #F4DE4B;
          font-weight: bold;
        }
      }
    }
    &-ornament {
      width: 1px;
      height: 86%;
      margin-top: 7%;
      background: linear-gradient(0deg, rgba(255,255,255,0) 0%, #FFFFFF 50%, rgba(255,255,255,0) 100%);
      opacity: 0.5;
    }
    &-info {
      width: 45%;
      padding: 0.2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      &-item {
        position: relative;
        padding-left: 0.18rem;
        font-size: 0.2rem;
        color: #FFFFFF;
        &-square {
          position: absolute;
          left: 0;
          top: 0.02rem;
          width: 0.1rem;
          height: 0.1rem;
          background: linear-gradient(0deg, #09E05F 0%, #7FE9A2 100%);
          // border: 0.1rem solid;
          // border-image: linear-gradient(0deg, #09E05F, #7FE9A2) 10 10;
        }
        &-long {
          position: absolute;
          width: 0.1rem;
          height: 0.26rem;
          left: 0.05rem;
          top: 0.12rem;
          border-bottom: 1px solid #7FE9A2;
          border-left: 1px solid #7FE9A2;
        }
        &-text {
          font-size: 0.16rem;
          // color: #FFFFFF;
        }
        &-num {
          margin-top: 0.14rem;
          // font-size: 14px;
          // color: #FFFFFF;
          span {
            color: #00CF52;
          }
        }
      }
      &-item2 {
        &-square {
          background: linear-gradient(0deg, #1B82F5 0%, #39D7FF 100%);
        }
        &-long {
          border-bottom: 1px solid rgba(27, 131, 245, .5);
          border-left: 1px solid rgba(27, 131, 245, .5);
        }
        &-num {
          span {
            color: rgba(27, 131, 245, 1);
          }
        }
      }
      &-item3 {
        &-square {
          background: linear-gradient(0deg, #F16145 0%, #FF962F 100%);
        }
        &-long {
          border-bottom: 1px solid rgba(241, 99, 69, .5);
          border-left: 1px solid rgba(241, 99, 69, .5);
        }
        &-num {
          span {
            color: rgba(241, 99, 69, 1);
          }
        }
      }
    }
  }
  &-type {
    height: 50%;
    &-item {
      display: flex;
      align-items: center;
      width: 80%;
      height: 33%;
      // height: 1.07rem;
      margin: auto;
      padding: 0 5%;
      background-image: url('./../../../../assets/images/bigScreen/customerInfo/typeIcon.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
        cursor: pointer;
      &-text {
        flex: 1;
        text-align: center;
        font-weight: 500;
        font-size: 0.16rem;
        margin-top: 0.18rem;
        color: #fff;
        span {
          margin-right: 0.08rem;
        }
      }
      &-icon {
        display: block;
        height: 30%;
        cursor: pointer;
        padding: 0 8%;
      }
    }
    &-current {
      background-image: url('./../../../../assets/images/bigScreen/customerInfo/currentTypeIcon.png');
    }
  }
}
</style>