<template>
  <div class="model-box">
    <div class="model-box-general">
      <img
        class="model-box-general-icon"
        v-if="!userType"
        src="../../../../assets/images/bigScreen/customerInfo/allUser.png"
        alt=""
        srcset=""
      />
      <img
        class="model-box-general-icon"
        v-else-if="userType == 1"
        src="../../../../assets/images/bigScreen/customerInfo/excellentUser.png"
        alt=""
        srcset=""
      />
      <img
        class="model-box-general-icon"
        v-else-if="userType == 2"
        src="../../../../assets/images/bigScreen/customerInfo/routineUser.png"
        alt=""
        srcset=""
      />
      <img
        class="model-box-general-icon"
        v-else-if="userType == 3"
        src="../../../../assets/images/bigScreen/customerInfo/riskUser.png"
        alt=""
        srcset=""
      />

      <div class="model-box-general-item">
        <div class="model-box-general-item-text">{{ userType == 1 ? '优质' : userType == 2 ? '常规' : userType == 3 ? '风险' : '全部' }}用户(位)</div>
        <div
          class="model-box-general-item-num"
          :class="['model-box-general-item-num' + userType]"
        >
          {{ options.allUser || '--' }}
        </div>
      </div>
      <div class="model-box-general-item">
        <div class="model-box-general-item-text">{{ userType == 1 ? '优质' : userType == 2 ? '常规' : userType == 3 ? '风险' : '全部' }}设备(台)</div>
        <div
          class="model-box-general-item-num"
          :class="['model-box-general-item-num' + userType]"
        >
          {{ options.allDevice || '--' }}
        </div>
      </div>
    </div>
    <div class="model-box-mes">
      <div class="model-box-mes-left">
        <div class="model-box-mes-left-item">
          <div class="model-box-mes-left-circle"></div>
          <div class="model-box-mes-left-item-text">优质用户</div>
        </div>
        <div class="model-box-mes-left-item">
          <div class="model-box-mes-left-circle2"></div>
          <div class="model-box-mes-left-item-text">常规用户</div>
        </div>
        <div class="model-box-mes-left-item">
          <div class="model-box-mes-left-circle3"></div>
          <div class="model-box-mes-left-item-text">风险用户</div>
        </div>
      </div>
      <div class="model-box-mes-right">设备数量</div>
    </div>
    <vue-seamless-scroll
      :data="options.userDeviceStatistics"
      :style="{
        width: '100%',
        height: 'calc(99% - 1.22rem)',
        overflow: 'hidden',
      }"
      :class-option="defaultOption"
    >
      <div
        class="user-box-item"
        v-for="(item, index) in options.userDeviceStatistics"
        :key="index"
      >
        <div class="user-box-item-icon" v-if="index > 2">
          {{ index + 1 }}
        </div>
        <img class="user-box-item-icon2" v-else-if="index == 0" src="../../../../assets/images/bigScreen/customerInfo/goldIcon.png" alt="" srcset="">
        <img class="user-box-item-icon2" v-else-if="index == 1" src="../../../../assets/images/bigScreen/customerInfo/silverIcon.png" alt="" srcset="">
        <img class="user-box-item-icon2" v-else-if="index == 2" src="../../../../assets/images/bigScreen/customerInfo/copperIcon.png" alt="" srcset="">
        <div class="user-box-item-name" @click="handleNavi('/BigScreenDisplayCustomerRecord')">{{ item.customerName || '--' }}</div>
        <div class="user-box-item-circle" v-if="item.customerLv == 1"></div>
        <div class="user-box-item-circle2" v-else-if="item.customerLv == 2"></div>
        <div class="user-box-item-circle3" v-else></div>
        <div class="situation-box-progress">
            <div
              class="situation-box-progress-scale"
              :style="{ width: (item.deviceScore || 0) + '%' }"
            ></div>
        </div>
        <div class="user-box-item-num">{{ item.deviceNumber || '--' }}</div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    vueSeamlessScroll,
  },
  props: {
    userType: {
      type: [Number, String],
      default: "",
    },
    options: {
      type: Object,
      default: ()=>{}
    }
  },
  data() {
    return {
      situationoptions: [{}, {}, {}, {}, {}],
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 20, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
    };
  },
  methods: {
    handleNavi(path){
      this.$router.push({
        path
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.model-box {
  height: 96%;
  padding: 8%;
  &-general {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.1rem;
    &-icon {
      width: 0.98rem;
      height: 1.22rem;
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-text {
        text-align: center;
        font-size: 0.16rem;
        color: #fff;
      }
      &-num {
        text-align: center;
        font-size: 0.24rem;
        color: #1088ff;
        margin-top: 0.1rem;
        font-family: "ZhanKuQingKeHuangYouTi";
      }
      &-num1 {
        color: rgba(70, 209, 125, 1);
      }
      &-num2 {
        color: rgba(25, 70, 200, 1);
      }
      &-num3 {
        color: rgba(217, 66, 74, 1);
      }
    }
  }
  &-mes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.12rem;
    color: #fff;
    margin: 0 0.08rem;
    height: 4%;
    &-left {
      display: flex;
      align-items: center;
      &-item {
        display: flex;
        align-items: center;
        margin-right: 0.12rem;
        &-text {
          margin-left: 0.06rem;
        }
      }
      &-circle {
        width: 0.1rem;
        height: 0.1rem;
        border-radius: 50%;
        background: linear-gradient(0deg, #09e05f 0%, #7fe9a2 100%);
      }
      &-circle2 {
        width: 0.1rem;
        height: 0.1rem;
        border-radius: 50%;
        background: linear-gradient(0deg, #1b82f5 0%, #39d7ff 100%);
      }
      &-circle3 {
        width: 0.1rem;
        height: 0.1rem;
        border-radius: 50%;
        background: linear-gradient(0deg, #f16145 0%, #ff962f 100%);
      }
    }
    &-right {
    }
  }
  .user-box-item {
    display: flex;
      font-size: 0.14rem;
      color: #ffffff;
      margin-top: 0.22rem;
    &-icon {
      width: 0.2rem;
      height: 0.2rem;
      line-height: 0.2rem;
      font-size: 0.12rem;
      color: #ffffff;
      text-align: center;
      border-radius: 50%;
      background-color: rgba(61, 143, 255, 0.41);
    }
    &-icon2 {
      width: 0.2rem;
      height: 0.24rem;
    }
    &-name {
      width: 0.68rem;
      white-space: nowrap; /* 不换行 */
      overflow: hidden; /* 溢出部分隐藏 */
      text-overflow: ellipsis; /* 显示省略号 */
      color: #fff;
      margin-left: 0.12rem;
      cursor: pointer;
      margin-right: 0.08rem;
    }
    &-name:hover {
      color: #06AAFF;
      text-decoration-line: underline;
    }
    &-num {
      width: 0.68rem;
      white-space: nowrap; /* 不换行 */
      overflow: hidden; /* 溢出部分隐藏 */
      text-overflow: ellipsis; /* 显示省略号 */
      margin-left: 0.12rem;
      text-align: center;
    }
    &-circle {
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
      background: linear-gradient(0deg, #09e05f 0%, #7fe9a2 100%);
    }
    &-circle2 {
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
      background: linear-gradient(0deg, #1b82f5 0%, #39d7ff 100%);
    }
    &-circle3 {
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
      background: linear-gradient(0deg, #f16145 0%, #ff962f 100%);
    }
  }
  .situation {
    position: relative;
    height: 55%;
    overflow: auto;
    padding: 8% 3% 3%;
    &-box {
      display: flex;
      align-items: center;
      color: #fff;
      margin-top: 1.6%;
      padding: 0 2%;
      &-left {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 1.6rem;
        font-size: 0.14rem;
        margin-left: 0.1rem;
      }
      &-progress {
        position: relative;
        flex: 1;
        height: 0.08rem;
        background-color: rgba(13, 82, 122, 1);
        margin: 0 4px;
        margin-left: 0.12rem;
        border-radius: 0.09rem;
        overflow: hidden;
        &-scale {
          height: 0.08rem;
          background: linear-gradient(90deg, #0076E3, #18A9F6);
          border-radius: 0.09rem;
        }
        &-ab {
          position: absolute;
          top: 50%;
          left: 0.12rem;
          font-weight: bold;
          font-size: 0.13rem;
          color: #FFFFFF;
          transform: translateY(-50%);
        }
        
      }
      &-right {
        width: 0.88rem;
        font-size: 0.1rem;
        text-align: center;
        span {
          font-family: "ZhanKuQingKeHuangYouTi";
          margin-right: 2px;
          font-size: 0.16rem;
        }
      }
    }
  }
}
</style>