<template>
  <div class="scheme-box" ref="model-box">
      <vue-seamless-scroll :data="situationoptions"
        :style="{ width: '100%',height: boxH+'px', overflow: 'hidden' }"
        :class-option="defaultOption">
        <div class="scheme-box-item">
          {{ options || '--' }}
        </div>
        <div></div>
      </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll';
export default {
  components: {
    vueSeamlessScroll
  },
  props: {
    options: {
      type: String,
      default: '--'
    }
  },
  data() {
    return {
      situationoptions: [{},{}],
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      },
      boxH: 0,
    };
  },
  created() {
    //  this.init();
    this.$nextTick(() => {
      this.boxH = this.$refs["model-box"].offsetHeight;
    });
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.scheme-box {
  padding: 1%;
  height: 82%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  &-content {
    flex: 1;
  }
  &-item {
    display: flex;
    // height: 0.33rem;
    line-height: 0.33rem;
    font-size: 0.14rem;
    color: #FEFEFE;
    // margin: 0 0.15rem;
    padding: 0.2rem;
    // &-flex {
    //   flex: 1;
    // }
    // &-right {
    //   width: 1.4rem;
    //   // text-align: center;
    // }
    // &-head {
    //   height: 0.4rem;
    //   line-height: 0.4rem;
    //   font-weight: 500;
    //   font-size: 0.17rem;
    //   color: #20CEFA;
    // }
  }
}
.scheme-box-item:nth-child(even) {
    background: rgba(18,39,153,0.3);
}
</style>